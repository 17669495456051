import {
  Grid,
  Typography,
  FormGroup,
} from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Badge from '@mui/material/Badge';

import { TitleIcon } from './TitleIcon/TitleIcon';

import { ObjectType } from '../../../../../../../types/objectType';
import { namespaces } from '../../../../../../../i18n/i18n.constants';
import { WORDEXCEPTIONS, WORDEXCEPTIONSUPPERCASE } from '../../../../../../../config/wordExceptionsToCapitalize';
import { capitalizeString } from '../../../../../../../helpers/stringHelpers';

import titleStyle from './titleStyle';

export const Title = (
  {
    filterType,
    tags,
    isOpenFilter,
    toggleIsOpenFilter,
    tooltipMessage,
  }: ObjectType,
) => {
  const { t } = useTranslation(namespaces.pages.resumesSearch);
  const tagsLength = Array.isArray(tags) ? tags.length : 0;
  const invisible = isOpenFilter || tagsLength === 0;
  const classes = titleStyle();

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      title={tooltipMessage}
      children={props.children}
      classes={{ popper: className }}
      placement='top'
    />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      color: '#000000',
      boxShadow: '1px 3px 8px 0px rgba(0, 0, 0, 0.1)',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '12px',
      position: 'relative',
      top: '15px',
      width: '200px',
      padding: '5px 10px',
    },
  }));

  return (
    <FormGroup
      row
      className={classes.titleContainer}
    >
      <Grid
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        onClick={() => toggleIsOpenFilter()}
        className={classes.title}
      >
        <Grid
          container
          alignItems='center'
          className={classes.titleBadgeTypographyContainer}
        >
          <Badge
            color="primary"
            variant="dot"
            overlap="circular"
            invisible={invisible}
          >
            <TitleIcon filterType={filterType} />
          </Badge>
          <Typography
            variant='body1'
            className={classes.titleTypography}
          >
            {capitalizeString(t(`filters.${filterType}`), WORDEXCEPTIONS, WORDEXCEPTIONSUPPERCASE)}
            {tooltipMessage
              ? <LightTooltip title="Add">
                <InfoOutlinedIcon sx={{ color: '#666666', marginLeft: '5px' }} />
              </LightTooltip>
              : null}
          </Typography>
        </Grid>
        <Grid item>
          {isOpenFilter ? <ExpandLess /> : <ExpandMore />}
        </Grid>
      </Grid >
    </FormGroup>
  );
};
