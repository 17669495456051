import {
  Grid,
} from '@mui/material';
import { ObjectType } from '../../../../../../../../types/objectType';
import modalBodyStyle from './modalBodyStyle';

export const ModalBody = ({ children }: ObjectType) => {
  const classes = modalBodyStyle();
  return (<Grid
    container
    style={
      {
        minHeight: '77%',
        maxHeight: '77%',
      }
    }
    className={classes.infiniteScroll}
  >
    {children}
  </Grid>);
};
