import { makeStyles } from '@material-ui/styles';

export default makeStyles({
  titleContainer: {
    paddingRight: '0.7rem',
    paddingLeft: '0.7rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(33, 33, 33, 0.05)',
    },
  },
  title: {
    minHeight: '54px',
  },
  titleBadgeTypographyContainer: {
    width: '83%',
    marginLeft: '0.25rem',
  },
  titleTypography: {
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    padding: 0,
    maxWidth: '78%',
    marginLeft: '0.5rem',
    whiteSpace: 'nowrap',
  },
});
